.blog-content {
  text-align: justify;
  line-height: 1.8;
  font-size: 1.2rem; /* Increased font size */
  font-family: 'Merriweather', serif;
  color: #333; /* Darker color for sharper contrast */
}

.blog-content p {
  margin-bottom: 1.5rem;
}

.blog-content h1 {
  font-size: 2.5rem; /* Larger heading size */
  font-weight: 800; /* Extra bold for main headings */
  margin-top: 2rem;
}

.blog-content h2 {
  font-size: 2rem; /* Increased size for secondary headings */
  font-weight: 700;
  margin-top: 1.8rem;
}

.blog-content h3 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-top: 1.5rem;
}

.blog-content h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.3rem;
}

.blog-content ul, .blog-content ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.blog-content li {
  margin-bottom: 0.5rem;
  font-size: 1.1rem; /* Slightly larger for readability */
}

.blog-content img {
  border-radius: 0.5rem;
  margin: 1.5rem 0;
}

.blog-content a {
  color: #2563eb; /* Slightly darker blue for readability */
  font-weight: 600;
  text-decoration: underline;
}

.blog-content blockquote {
  border-left: 4px solid #2563eb;
  padding-left: 1rem;
  color: #555;
  font-style: italic;
  margin: 1.5rem 0;
  font-size: 1.15rem; /* Slightly larger for readability */
}


.post-desc {
  font-size: 1.25rem; /* Slightly larger font size for emphasis */
  color: #555; /* Softer color for a subtle, refined look */
  font-style: italic; /* Italicized for distinction */
  margin-bottom: 1.5rem; /* Add space between description and content */
  line-height: 1.6;
}

@media (min-width: 768px) {
  .post-desc {
    font-size: 1.4rem; /* Increase size on larger screens */
  }
}

.full-cover-image {
  width: 100%;
  height: auto;
  object-fit: contain; /* Adjusts to show the entire image */
}
