@keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
  }
  
.shake {
animation: shake 0.8s ease-in-out;
}

.form-container {
opacity: 0;
animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
  