/* Light Mode (default Quill style) */
.quill-light .ql-toolbar, 
.quill-light .ql-container {
  background-color: #ffffff;
  color: #000000;
  border-color: #e2e8f0; /* Light border */
}

/* Dark Mode */
.quill-dark .ql-toolbar,
.quill-dark .ql-container {
  background-color: #2d3748; /* Dark background */
  color: #e2e8f0; /* Light text color */
  border-color: #4a5568; /* Dark border */
}

.quill-dark .ql-snow .ql-picker {
  color: #e2e8f0; /* Light text for toolbar items */
}

.quill-dark .ql-snow .ql-stroke {
  stroke: #e2e8f0; /* Toolbar icons */
}

.quill-dark .ql-snow .ql-fill {
  fill: #e2e8f0; /* Toolbar icons */
}

.quill-dark .ql-snow .ql-picker-options {
  background-color: #4a5568; /* Dark background for dropdowns */
  color: #e2e8f0; /* Light text for dropdowns */
}

.quill-dark .ql-snow .ql-picker-item {
  color: #e2e8f0; /* Dropdown text color */
}
